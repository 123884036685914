const env = {
    // url: 'http://localhost:8080/simplifica-gabinete/api/',
    // api: 'http://localhost:8080/simplifica-gabinete/api/api/v1/',
    // baseUrl: 'http://localhost:8081/',
    baseUrl: 'https://simplificagabinete.techvision.dev/',
    url: 'https://apisimplificagabinete.techvision.dev/',
    api: 'https://apisimplificagabinete.techvision.dev/api/v1/',
    title: 'Simplifica Gabinete',
}

export default env;
