// import store from "@/state/store";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login"),
        meta: {
            title: "Login",
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (store.getters["auth/loggedIn"]) {
            //         next({name: "dashboard"});
            //     } else {
            //         next();
            //     }
            // },
        },
    },
    {
        path: "/esqueceu-senha",
        name: "forgot-password",
        component: () => import("@/views/account/forgot-password.vue"),
        meta: {
            title: "Recuperar Senha",
        },
    },
    {
        path: "/recuperar-senha/:token",
        name: "change-password",
        component: () => import("@/views/account/change-password.vue"),
        meta: {
            title: "Resetar Senha",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/account/logout"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/dashboard/index"),
    },

    {
        path: "/meu-perfil",
        name: "my-profile",
        meta: {
            title: "Meu Perfil",
            authRequired: true,
        },
        component: () => import("@/views/profile/my-profile.vue"),
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
        },
        component: () => import("@/views/management/users"),
    },

    {
        path: "/landing-page",
        name: "landing-page",
        meta: {
            title: "Landing Page",
            authRequired: true,
        },
        component: () => import("@/views/management/landing-page"),
    },

    {
        path: "/lp/:slug",
        name: "landing-page-view",
        meta: {
            title: "Página do Vereador",
        },
        component: () => import("@/views/landing-page/index.vue"),
    },

    {
        path: "/lp/:slug/agenda/:cabinet",
        name: "landing-page-availability",
        meta: {
            title: "Agenda do Vereador",
        },
        component: () => import("@/views/landing-page/availability.vue"),
    },

    {
        path: "/disponibilidades",
        name: "availabilities",
        meta: {
            title: "Disponibilidades",
            authRequired: true,
        },
        component: () => import("@/views/management/availabilities.vue"),
    },

    {
        path: "/detalhes-gabinete",
        name: "cabinet-details",
        meta: {
            title: "Detalhes do Gabinete",
            authRequired: true,
        },
        component: () => import("@/views/management/cabinet-details"),
    },

    {
        path: "/categorias",
        name: "categories",
        meta: {
            title: "Categorias",
            authRequired: true,
        },
        component: () => import("@/views/management/categories"),
    },

    {
        path: "/instituicoes",
        name: "institutions",
        meta: {
            title: "Instituições",
            authRequired: true,
        },
        component: () => import("@/views/management/institutions"),
    },

    {
        path: "/contatos",
        name: "contacts",
        meta: {
            title: "Contatos",
            authRequired: true,
        },
        component: () => import("@/views/contact/index"),
    },

    {
        path: "/mensagens",
        name: "messages",
        meta: {
            title: "Mensagens",
            authRequired: true,
        },
        component: () => import("@/views/message/index"),
    },
    {
        path: "/agenda",
        name: "calendar",
        meta: {
            title: "Agenda",
            authRequired: true,
        },
        component: () => import("@/views/calendar/index.vue"),
    },

    {
        path: "/documentos/configuracoes",
        name: "documents-management",
        meta: {
            title: "Documentos Configurar",
            authRequired: true,
        },
        component: () => import("@/views/document/management.vue"),
    },

    {
        path: "/documentos/uploads",
        name: "documents-uploads",
        meta: {
            title: "Upload de Documentos",
            authRequired: true,
        },
        component: () => import("@/views/document/upload.vue"),
    },

    {
        path: "/documentos/novos-documentos",
        name: "documents-news",
        meta: {
            title: "Novos Documentos",
            authRequired: true,
        },
        component: () => import("@/views/document/news.vue"),
    },

    {
        path: "/projetos",
        name: "projects",
        meta: {
            title: "Projetos",
            authRequired: true,
        },
        component: () => import("@/views/project/index.vue"),
    },
];
