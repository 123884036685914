import $ from 'jquery'
import router from '@/router';
import {push} from 'notivue';
import Swal from "sweetalert2";


export function maskMoneyTable(value) {
    var money = parseFloat(value);
    money = money.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    return money;
}

export function formatMoney(id) {
    const value = document.getElementById(id).value;
    // Remove todos os caracteres que não sejam dígitos
    if (value === '') return;

    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    // Formata o valor como moeda (adiciona símbolo de moeda e sinal de negativo)
    const format = 'R$ ' + integerPart + ',' + decimalPart;
    return document.getElementById(id).value = format;
}

export function toDecimal(value) {
    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    return integerPart + ',' + decimalPart;
}

export function getUrl(session) {
    const values = JSON.parse(localStorage.getItem(session));
    let url = values.url + '/?';
    const params = values.params;
    const keys = Object.keys(values.params);

    for (let i = 0; i < keys.length; i++) {
        let result = params[keys[i]];
        url += `&${keys[i]}=${result}`;
    }

    return url;
}

export function formDataToJson(id) {
    // Acessar o formulário pelo seu ID
    const form = document.getElementById(id);
    if (!form) {
        console.error('Formulário não encontrado!');
        return null;
    }

    // Criar uma instância de FormData
    const formData = new FormData();

    formData.append('id', document.getElementById('id').value);

    const inputs = form.getElementsByClassName('form-control');
    Array.from(inputs).forEach(input => {
        // Verificar se é um campo de entrada de arquivo
        if (input.type === 'file') {
            // Adicionar cada arquivo selecionado ao objeto FormData
            for (let i = 0; i < input.files.length; i++) {
                formData.append(input.name, input.files[i]);
            }
        } else if (input.type !== 'submit' && input.type !== 'button' && input.name) {
            // Para outros tipos de campos, apenas adicionar ao FormData
            // Ignorando botões de envio e outros elementos interativos que não contêm dados "submetíveis"
            formData.append(input.name, input.value);
        }
    })


    return formData;
}

export function Forbidden(response) {

    if (response.response.status === 401 || response.response.status === 403) {
        localStorage.removeItem('token');
        router.push({
            name: 'login'
        })
    }

    notifyError(response.response.data.message);
}

export function notifyError(message) {
    // const decode = JSON.parse(message);
    // if (decode.message) {
    //
    //     if (document.getElementById(decode.input)) document.getElementById(decode.input).classList.add('is-invalid');
    //
    //     push.error({
    //         title: 'Erro!',
    //         message: decode.message,
    //         duration: 5000
    //     })
    // } else {
        push.error({
            title: 'Erro!',
            message,
            duration: 5000
        })
    // }
}

export function notifySuccess(message) {
    push.success({
        title: 'Sucesso!',
        message,
        duration: 5000
    })
}

export function maskCpfCnpj(value) {

    if (value.length <= 11) {
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
}

export async function deleteElement(message) {
    const text = message || "Seus dados serão removidos e não poderão mais ser recuperados.";
    return Swal.fire({
        title: "Você tem certeza?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
    }).then((result) => {
        return result.isConfirmed;
    });
}

export function loadTable() {
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '.2'
    loadCard();
}

export function loadCard(id) {
    if (id) {
        const element = document.getElementById(id);
        element.style.opacity = '.2'
    }
    const placeholderElement = document.querySelector('.simplebar-placeholder');
    const width = parseInt(placeholderElement.offsetWidth) / 2 + 30;

    if (document.getElementById('spinner')) {
        document.getElementById('spinner').style.display = 'block'
        document.getElementById('spinner').style.right = `calc(50% - ${width}px)`
    }
}

export function endLoadTable() {
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '1'

    endLoadCard();
}

export function endLoadCard(id) {
    if (id) {
        const element = document.getElementById(id);
        element.style.opacity = '1'
    }
    document.getElementById('spinner').style.display = 'none'
    document.getElementById('spinner').style.right = `calc(50% - 2.5rem)`
}

export function convertDateText(dateTime) {
    let date = dateTime.split(' ');
    const months = [
        'Jan', 'Fev', 'Mar', 'Abr',
        'Mai', 'Jun', 'Jul', 'Ago',
        'Set', 'Out', 'Nov', 'Dez',
    ];

    date = date[0].split('-');
    const month = months[parseInt(date[1]) - 1];

    return `${date[2]} ${month}, ${date[0]}`;
}

export function convertHour(dateTime) {
    let date = dateTime.split(' ');
    date = date[1].split('.');

    return date[0];
}

export function showHide(show, hide, save) {
    if (save) {
        const card = document.getElementById(save);
        const height = card.offsetHeight;
        card.style.height = height + 'px';
    }
    $('#' + hide).hide();
    $('#' + show).show(300);
}

export function generateNickname(fullName) {
    // Dividir o nome completo em partes
    let nameParts = fullName.trim().split(" ");

    // Obter a primeira letra do primeiro nome
    let firstNameInitial = nameParts[0].charAt(0);

    // Obter a primeira letra do último nome
    let lastNameInitial = nameParts[nameParts.length - 1].charAt(0);

    // Combinar as letras para formar o nickname
    let nickname = firstNameInitial + lastNameInitial;

    return nickname.toUpperCase(); // Opcional: converter para maiúsculas
}

export function addZeros(string) {
    return String(string).padStart(2, '0');
}

export function httpError(response) {
    if (response.status === 401) {
        notifyError('Você precisa se autenticar!');
        router.push('/login');
    }

    if (typeof response.data === 'string') {
        notifyError(response.data);
    }
}
